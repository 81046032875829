var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("h3", { staticClass: "title" }, [_vm._v("Задайте пароль")]),
      _c("error-messages", { attrs: { messages: _vm.error_messages } }),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitHander.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("span", { staticClass: "input-icon" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password,
                    expression: "password",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "password",
                  type: "password",
                  name: "password",
                  placeholder: "Пароль",
                  required: "",
                },
                domProps: { value: _vm.password },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password = $event.target.value
                  },
                },
              }),
              _c("i", { staticClass: "fa fa-unlock-alt" }),
            ]),
          ]),
          _c("div", { staticClass: "form-group" }, [
            _c("span", { staticClass: "input-icon" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.password_confirmation,
                    expression: "password_confirmation",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "password_confirmation",
                  type: "password",
                  name: "password_confirmation",
                  placeholder: "Подтвердите пароль",
                  required: "",
                },
                domProps: { value: _vm.password_confirmation },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.password_confirmation = $event.target.value
                  },
                },
              }),
              _c("i", { staticClass: "fa fa-unlock-alt" }),
            ]),
          ]),
          _vm._m(0),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-actions center" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Продолжить")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }