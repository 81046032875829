<template lang="pug">
  #wrapper
    h3.title Задайте пароль

    error-messages(:messages='error_messages')

    form(@submit.prevent='submitHander')
      .form-group
        span.input-icon
          input.form-control#password(
            type='password'
            name='password'
            placeholder='Пароль'
            required v-model='password'
          )
          i.fa.fa-unlock-alt

      .form-group
        span.input-icon
          input.form-control#password_confirmation(
            type='password'
            name='password_confirmation'
            placeholder='Подтвердите пароль'
            required v-model='password_confirmation'
          )
          i.fa.fa-unlock-alt

      .form-actions.center
        button.btn.btn-primary(type='submit') Продолжить
</template>

<script>
import ErrorMessages from '../components/ErrorMessages.vue'
import UrlMixin from '../mixins/Url'
import CreatedMixins from '../mixins/Created'
import HandleResponse from '../mixins/HandleResponse'

export default {
  name: 'password',

  components: { ErrorMessages },
  mixins: [ UrlMixin, CreatedMixins, HandleResponse ],

  data () {
    return {
      password: null,
      password_confirmation: null,
      error_messages: []
    }
  },

  mounted () {
    document.getElementById('password').focus()
  },

  methods: {
    submitHander () {
      this.error_messages = []
      if (!this.password) this.error_messages.push('Пароль не может быть пустым')
      if (!this.password_confirmation) this.error_messages.push('Подтверждение пароля не может быть пустым')
      if (this.password !== this.password_confirmation)
        this.error_messages.push('Пароли не совпадают')

      if (this.error_messages.length > 0) return
      this.makeRequest()
    },

    makeRequest () {
      var params = {
        user: {
          email: this.$store.state.user.email,
          password: this.password,
          password_confirmation: this.password_confirmation
        }
      }

      this.$http.post(`${this.url}/password`, params).then(response => {
        if (response.body.error_code == 0)
          this.handleResponse(response.body.data)
        else this.error_messages = response.body.error_message
      }, response => {
        this.error_messages = response.body.error_message
      });
    }
  }
}
</script>

<style lang="css" scoped>
</style>
