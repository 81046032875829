var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { attrs: { id: "wrapper" } }, [
    _c("h3", { staticClass: "title" }, [
      _vm._v("Адрес электронной почты уже зарегистрирован"),
    ]),
    _c("p", [
      _vm._v(
        "Адрес электронной почты уже зарегистрирован в системе, но не подтвержден. Для повторной отправки кода подтверждения нажмите на кнопку ниже"
      ),
    ]),
    _c(
      "form",
      {
        on: {
          submit: function ($event) {
            $event.preventDefault()
            return _vm.submitHander.apply(null, arguments)
          },
        },
      },
      [
        _c("div", { staticClass: "form-group" }, [
          _c("span", { staticClass: "input-icon" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.email,
                  expression: "email",
                },
              ],
              staticClass: "form-control",
              attrs: {
                id: "email",
                name: "email",
                placeholder: "Адрес электронной почты",
                required: "",
              },
              domProps: { value: _vm.email },
              on: {
                input: function ($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.email = $event.target.value
                },
              },
            }),
            _c("i", { staticClass: "fa fa-envelope" }),
          ]),
        ]),
        _vm._m(0),
        _c("div", { staticClass: "form-actions center" }, [
          _c(
            "button",
            {
              staticClass: "btn btn-default",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.$router.push({ name: "email" })
                },
              },
            },
            [_vm._v("Назад")]
          ),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-actions center" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submint" } },
        [_vm._v("Выслать")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }