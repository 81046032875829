<template lang="pug">
  .center
    i.fa.fa-cog.fa-spin(style="font-size:300%; color: #428bca;")
    p Подготавливаем ваш аккаунт
    p Это может занять до 5 минут.
    p Вы можете закрыть окно,
    p когда все будет готово мы пришлем письмо на
    p#email {{ email }}
</template>

<script>
import ErrorMessages from '../components/ErrorMessages.vue'
import UrlMixin from '../mixins/Url'
import LoginScript from '../mixins/LoginScript'
import CreatedMixins from '../mixins/Created'
import Signed from '../components/Signed.vue'

export default {
  name: 'complex_status',

  components: { ErrorMessages, Signed },
  mixins: [ UrlMixin, LoginScript, CreatedMixins ],

  data () {
    return {
      name_ru: null,
      subdomain: null,
      error_messages: [],
      timer: null
    }
  },

  mounted () {
    this.timer = setInterval(this.makeRequest, 5000)
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },

  computed: {
    email () {
      return this.$store.state.user.email
    }
  },

  methods: {
    makeRequest () {
      var headers = {
        'X-Api-Token': this.$store.state.api_token
      }
      var params = {
        navigation_complex_id: this.$route.params.id
      }
      this.$http.post(`${this.url}/complex_status`, params, { headers: headers }).then(response => {
        var body = response.body
        if (body.error_code == 0) {
          this.checkComplex(body.data)
        } else {
          this.error_messages = response.body.error_message
        }
      }, response => {
        this.error_messages = response.body.error_message
      })
    },

    checkComplex (data) {
      this.$store.commit('updateComplex', data)
      if (data.is_building === false && data.demo_data_status === 'loaded')
        this.$router.push({ name: 'complexes-list' })
    }
  }
}
</script>

<style lang="css" scoped>
.center {
  padding: 50px 0 0 0;
}

.center p {
  margin-bottom: 3px;
}

.center p:first-of-type {
  padding-top: 10px;
}

#email {
  font-weight: bold;
}
</style>
