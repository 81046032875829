import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    protocol: null,
    url_base: null,
    user: null,
    psw: null,
    unregistered_email: null,
    signed: false,
    api_token: null,
    complexes: []
  },

  mutations: {
    setProtocol (state, val) { state.protocol = val },

    setUrlBase (state, val) { state.url_base = val },

    setUser (state, user) { state.user = user },

    setUserPassword (state, password) { state.user.password = password },

    setUnregisteredEmail (state, email) { state.unregistered_email = email },

    setApiToken (state, token) { state.api_token = token },

    setSigned (state, val) { state.signed = val },

    setComplexes (state, complexes) { state.complexes = complexes },

    addComplex (state, complex) { state.complexes.push(complex) },

    updateComplex (state, complex) {
      const item = state.complexes.find(item => item.id === complex.id);
      Object.assign(item, complex);
    }
  }
})
