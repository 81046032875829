var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("h3", [_vm._v("Подтвердите сброс пароля!")]),
      _c("p", [
        _vm._v(
          "Мы отправили 6-значный код подтверждения на " +
            _vm._s(_vm.$store.state.email) +
            ". Он скоро истечет, поэтому введите его в ближайшее время."
        ),
      ]),
      _c("error-messages", { attrs: { messages: _vm.error_messages } }),
      _c("div", { attrs: { id: "code" } }, [
        _c("div", { staticClass: "code-group" }, [
          _c("div", { staticClass: "splited-input left-item" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.number_1,
                  expression: "number_1",
                },
              ],
              attrs: { id: "number-1", type: "number", maxlength: "1" },
              domProps: { value: _vm.number_1 },
              on: {
                focus: function ($event) {
                  return _vm.focus(1)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.number_1 = $event.target.value
                  },
                  function ($event) {
                    return _vm.inputed(1)
                  },
                ],
              },
            }),
          ]),
          _c("div", { staticClass: "splited-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.number_2,
                  expression: "number_2",
                },
              ],
              attrs: { id: "number-2", type: "number", maxlength: "1" },
              domProps: { value: _vm.number_2 },
              on: {
                focus: function ($event) {
                  return _vm.focus(2)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.number_2 = $event.target.value
                  },
                  function ($event) {
                    return _vm.inputed(2)
                  },
                ],
              },
            }),
          ]),
          _c("div", { staticClass: "splited-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.number_3,
                  expression: "number_3",
                },
              ],
              attrs: { id: "number-3", type: "number", maxlength: "1" },
              domProps: { value: _vm.number_3 },
              on: {
                focus: function ($event) {
                  return _vm.focus(3)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.number_3 = $event.target.value
                  },
                  function ($event) {
                    return _vm.inputed(3)
                  },
                ],
              },
            }),
          ]),
        ]),
        _c("div", { staticClass: "code-group code-span" }, [_vm._v("—")]),
        _c("div", { staticClass: "code-group" }, [
          _c("div", { staticClass: "splited-input left-item" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.number_4,
                  expression: "number_4",
                },
              ],
              attrs: { id: "number-4", type: "number", maxlength: "1" },
              domProps: { value: _vm.number_4 },
              on: {
                focus: function ($event) {
                  return _vm.focus(4)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.number_4 = $event.target.value
                  },
                  function ($event) {
                    return _vm.inputed(4)
                  },
                ],
              },
            }),
          ]),
          _c("div", { staticClass: "splited-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.number_5,
                  expression: "number_5",
                },
              ],
              attrs: { id: "number-5", type: "number", maxlength: "1" },
              domProps: { value: _vm.number_5 },
              on: {
                focus: function ($event) {
                  return _vm.focus(5)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.number_5 = $event.target.value
                  },
                  function ($event) {
                    return _vm.inputed(5)
                  },
                ],
              },
            }),
          ]),
          _c("div", { staticClass: "splited-input" }, [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.number_6,
                  expression: "number_6",
                },
              ],
              attrs: { id: "number-6", type: "number", maxlength: "1" },
              domProps: { value: _vm.number_6 },
              on: {
                focus: function ($event) {
                  return _vm.focus(6)
                },
                input: [
                  function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.number_6 = $event.target.value
                  },
                  function ($event) {
                    return _vm.inputed(6)
                  },
                ],
              },
            }),
          ]),
        ]),
      ]),
      _c("p", [
        _vm._v(
          "Держите это окно открытым. После проверки кода вы автоматический перейдете к следующему шагу."
        ),
      ]),
      _c("div", { staticClass: "form-actions center" }, [
        _c(
          "button",
          {
            staticClass: "btn btn-default",
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.$router.push({ name: "sign-in" })
              },
            },
          },
          [_vm._v("Назад")]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }