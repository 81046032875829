<template lang="pug">
  #wrapper

    h3 Подтвердите ваш Адрес электронной почты

    p Мы отправили 6-значный код подтверждения на {{ $store.state.email }}. Он скоро истечет, поэтому введите его в ближайшее время.

    error-messages(:messages='error_messages')

    #code
      .code-group
        .splited-input.left-item
          input#number-1(type='number' maxlength='1' v-model='number_1' @focus='focus(1)' @input='inputed(1)')
        .splited-input
          input#number-2(type='number' maxlength='1' v-model='number_2' @focus='focus(2)' @input='inputed(2)')
        .splited-input
          input#number-3(type='number' maxlength='1' v-model='number_3' @focus='focus(3)' @input='inputed(3)')
      .code-group.code-span —
      .code-group
        .splited-input.left-item
          input#number-4(type='number' maxlength='1' v-model='number_4' @focus='focus(4)' @input='inputed(4)')
        .splited-input
          input#number-5(type='number' maxlength='1' v-model='number_5' @focus='focus(5)' @input='inputed(5)')
        .splited-input
          input#number-6(type='number' maxlength='1' v-model='number_6' @focus='focus(6)' @input='inputed(6)')

    p Держите это окно открытым. После проверки кода вы автоматический перейдете к следующему шагу.
</template>

<script>
import ErrorMessages from '../components/ErrorMessages.vue'
import UrlMixin from '../mixins/Url'
import CreatedMixins from '../mixins/Created'
import HandleResponse from '../mixins/HandleResponse'

export default {
  name: 'confirmation',

  components: { ErrorMessages },
  mixins: [ UrlMixin, CreatedMixins, HandleResponse ],

  data () {
    return {
      number_1: null,
      number_2: null,
      number_3: null,
      number_4: null,
      number_5: null,
      number_6: null,
      current: 0,
      error_messages: []
    }
  },

  watch: {
    current () {
      document.getElementById(`number-${this.current}`).focus()
    },

    isAllNuberPresent () {
      if (this.isAllNuberPresent) this.checkCode()
    }
  },

  mounted () {
    document.querySelectorAll('.splited-input input').forEach((el) => {
      el.addEventListener('keydown', this.keydownHandler)
    })
  },

  beforeDestroy () {
    document.querySelectorAll('.splited-input input').forEach((el) => {
      el.removeEventListener('keydown', this.keydownHandler)
    })
  },

  computed: {
    isAllNuberPresent () {
      var is_present = true
      for (var i = 1; i <= 6; i++) {
        if (!this[`number_${i}`] && is_present) is_present = false
      }
      return is_present
    }
  },

  methods: {
    inputed (i) {
      this.current += 1
      if (this.current > 6) this.current = 6
    },

    focus (i) {
      this.current = i
    },

    keydownHandler (e) {
      if (e.key !== 'Backspace') return

      if (this.current != 6) {
        this.current -= 1
        if (this.current < 1) this.current = 1
        this[`number_${this.current}`] = null
      } else {
        if (!this['number_6']) {
          this.current -= 1
          this[`number_${this.current}`] = null
        }
      }
    },

    checkCode () {
      if (!this.isAllNuberPresent) return

      var params = {
        user: {
          id: this.$store.state.user.id,
          confirmation_token: this.fetchCode()
        }
      }

      this.$http.post(`${this.url}/confirmation`, params).then(response => {
        var body = response.body
        if (body.error_code == 0) this.$router.push({ name: 'password' })
      }, response => {
        this.error_messages = response.body.error_message
      });
    },

    fetchCode () {
      return `${this.number_1}${this.number_2}${this.number_3}-${this.number_4}${this.number_5}${this.number_6}`
    }
  }
}
</script>

<style lang="css" scoped>
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

#code {
  display: table;
  margin: 20px auto;
}

.code-group {
  display: table-cell;
}

.code-span {
  padding: 0 15px;
}

.splited-input {
  display: table-cell;
  border: 2px solid #b1b1b1;
  overflow: hidden;
}

.splited-input input {
  width: 45px;
  text-align: center;
  font-size: 22px;
  font-weight: bold;
  padding: 15px;
  border: none;
}

.splited-input:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.splited-input:not(:first-child) {
  border-left: none;
}

.splited-input:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
</style>
