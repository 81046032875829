export default {
  methods: {
    handleResponse (data) {
      if (data.signed) {
        this.$store.commit('setSigned', true)
        this.$store.commit('setApiToken', data.api_token)
        this.$store.commit('setUser', data.user)
        this.$store.commit('setComplexes', data.complexes)
        this.$cookies.set('api_token', data.api_token, '3M')

        if (this.$store.state.signed && this.$store.state.complexes.length > 0)
          this.$router.push({ name: 'complexes-list' })
        else
          this.$router.push({ name: 'complex' })
      }
    },

    cookieDomain () {
      if (this.$store.state.mode === 'development') {
        return 'login.navimind.local:3000'
      } else {
        return 'login.navimind.org'
      }
    }
  }
}