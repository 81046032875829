<template lang="pug">
  .alert.alert-danger(v-if='list.length')
    b Пожалуйста исправьте указанные ошибки:
    ul
      li(v-for="(error, index) in list" :key='index') {{ error }}
</template>

<script>
export default {
  name: 'error-messages',
  props: {
    messages: {
      type: [ Array, String ],
      require: true,
      default: []
    }
  },

  computed: {
    list () {
      if (typeof this.messages == 'string')
        return [this.messages]
      else
        return this.messages
    }
  }
}
</script>

<style lang="css" scoped>
ul {
  padding: 5px 0 0 25px;
}
</style>
