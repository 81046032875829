export default {
  methods: {
    loginByForm (subdomain) {
      var f = document.createElement('form')
      f.action = this.signInUrl(subdomain)
      f.method = 'POST'

      var e = document.createElement('input')
      e.type = 'hidden'
      e.name = 'api_token'
      e.value = this.$store.state.api_token
      f.appendChild(e)

      document.body.appendChild(f)
      f.submit()
    }
  }
}
