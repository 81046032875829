var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "center" }, [
    _c("i", {
      staticClass: "fa fa-cog fa-spin",
      staticStyle: { "font-size": "300%", color: "#428bca" },
    }),
    _c("p", [_vm._v("Подготавливаем ваш аккаунт")]),
    _c("p", [_vm._v("Это может занять до 5 минут.")]),
    _c("p", [_vm._v("Вы можете закрыть окно,")]),
    _c("p", [_vm._v("когда все будет готово мы пришлем письмо на")]),
    _c("p", { attrs: { id: "email" } }, [_vm._v(_vm._s(_vm.email))]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }