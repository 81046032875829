<template lang="pug">
  #signed.text-right
    p Вы вошли как 
      b {{ email }} 
    p
      a(href='#' @click.prevent='logout') Выход
</template>

<script>
export default {
  name: 'singed',

  computed: {
    email () {
      if (this.$store.state.user)
        return this.$store.state.user.email
      else
        return ''
    }
  },

  methods: {
    logout () {
      this.$store.commit('setApiToken', null)
      this.$store.commit('setUser', null)
      this.$store.commit('setComplexes', [])
      this.$cookies.remove('api_token')
      this.$router.push({ name: 'email' })
    }
  }
}
</script>

<style lang="css" scoped>
p {
  margin: 0;
}
</style>
