var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("signed"),
      _c("h3", { staticClass: "title" }, [_vm._v("Выберите комплекс")]),
      _c("p", [
        _vm._v(
          "Ваш аккаунт привязан к следующим комплексам. Нажмите на комплекс в списке, чтоб авторизоваться в панели управление комплекса."
        ),
      ]),
      _c(
        "ul",
        _vm._l(_vm.complexes, function (item) {
          return _c(
            "li",
            {
              key: item.id,
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goto(item)
                },
              },
            },
            [
              _c("div", { staticClass: "data" }, [
                _c("h4", { staticClass: "name" }, [_vm._v(_vm._s(item.name))]),
                _c("p", { staticClass: "subdomain" }, [
                  _vm._v("Адрес: " + _vm._s(_vm.complexUrl(item.subdomain))),
                ]),
              ]),
              _vm.complexStatus(item)
                ? _c("div", { staticClass: "arrow" }, [
                    _c("i", { staticClass: "fa fa-chevron-right" }),
                  ])
                : _c("div", { staticClass: "gear" }, [_vm._m(0, true)]),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "w" }, [
      _c("i", {
        staticClass: "fa fa-cog fa-spin",
        staticStyle: { "font-size": "200%", color: "#428bca" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }