<template lang="pug">
  #wrapper
    h3.title Адрес электронной почты уже зарегистрирован

    p Адрес электронной почты уже зарегистрирован в системе, но не подтвержден. Для повторной отправки кода подтверждения нажмите на кнопку ниже

    form(@submit.prevent='submitHander')
      .form-group
        span.input-icon
          input.form-control#email(name='email' placeholder='Адрес электронной почты' required v-model='email')
          i.fa.fa-envelope
      .form-actions.center
        button.btn.btn-primary(type='submint') Выслать
      .form-actions.center
        button.btn.btn-default(@click.prevent='$router.push({ name: "email" })') Назад
</template>

<script>
import Url from '../mixins/Url'
import CreatedMixins from '../mixins/Created'

export default {
  name: 'resend-confirmation',
  mixins: [ Url, CreatedMixins ],

  data () {
    return {
      error_messages: [],
      email: ''
    }
  },

  created () {
    if (this.$store.state.user && this.$store.state.user.email)
      this.email = this.$store.state.user.email
  },

  mounted () {
    document.getElementById('email').focus()
  },

  methods: {
    submitHander () {
      this.error_messages = []

      if (!this.email) {
        this.error_messages.push('Адрес электронной почты не может быть пустым.')
      } else if (!this.validEmail(this.email)) {
        this.error_messages.push('Укажите корректный адрес электронной почты.')
      }

      if (this.error_messages.length > 0) return

      var params = {
        user: { email: this.email }
      }

      this.$http.post(`${this.url}/resend_confirmation`, params).then(response => {
        var body = response.body
        if (body.error_code == 0) {
          this.$router.push({ name: 'confirmation' })
        }
        else this.error_messages = response.body.error_message
      }, response => {
        this.error_messages = response.body.error_message
      })
    },

    validEmail (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
  }
}
</script>

<style lang="css" scoped>
.title {
  font-size: 1.6em
}

p {
  text-align: justify;
}

.btn {
  width: 112px;
}

.form-actions:last-child {
  margin-top: 0 !important;
}
</style>
