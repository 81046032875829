<template lang="pug">
  #wrapper
    error-messages(:messages='error_messages')

    form(@submit.prevent='submitHander')
      .form-group
        span.input-icon
          input.form-control#email(name='email' placeholder='Адрес электронной почты' required v-model='email')
          i.fa.fa-envelope

      .form-actions.center
        button.btn.btn-primary(type='submit') Продолжить
</template>

<script>
import ErrorMessages from '../components/ErrorMessages.vue'
import UrlMixin from '../mixins/Url'
import HandleResponse from '../mixins/HandleResponse'

export default {
  name: 'email',

  components: { ErrorMessages },
  mixins: [ UrlMixin, HandleResponse ],

  data () {
    return {
      email: null,
      error_messages: []
    }
  },

  created () {
    var api_token = this.$cookies.get('api_token')
    if (api_token && api_token !== 'null') {
      this.$store.commit('setApiToken', api_token)
      this.checkUserToken()
    }
  },

  methods: {
    checkUserToken () {
      var headers = {
        'X-Api-Token': this.$store.state.api_token
      }
      this.$http.post(`${this.url}/check`, {}, { headers: headers }).then(response => {
        if (response.body.error_code == 0) { this.handleResponse(response.body.data) }
      }, response => {
      });
    },

    submitHander () {
      this.error_messages = []

      if (!this.email) {
        this.error_messages.push('Адрес электронной почты не может быть пустым.')
      } else if (!this.validEmail(this.email)) {
        this.error_messages.push('Укажите корректный адрес электронной почты.')
      }

      if (this.error_messages.length > 0) return

      var params = {
        user: { email: this.email }
      }
      this.$http.post(`${this.url}/email`, params).then(response => {
        var body = response.body
        if (body.error_code == 0) {
          this.$store.commit('setUser', body.data.user)
          this.processUserState()
        } else if (body.error_code == 1 && body.unregistered_email == true) {
          this.$store.commit('setUnregisteredEmail', this.email)
          this.$router.push({ name: 'unregistered', params: { email: this.email } })
        }
      }, response => {
        this.error_messages = response.body.error_message
      });
    },

    validEmail (email) {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    processUserState () {
      var user = this.$store.state.user
      if (user.new_user)
        this.$router.push({ name: 'confirmation' })
      else {
        if (user.confirmed)
          this.$router.push({ name: 'sign-in' })
        else
          this.$router.push({ name: 'resend-confirmation' })
      }
    }
  }
}
</script>

<style lang="css" scoped>
h3.title {
  font-size: 19px;
}

#logo {
  height: 30px;
}
</style>
