<template lang="pug">
  #wrapper

    signed

    h3 Название объекта

    error-messages(:messages='error_messages')

    form(@submit.prevent='submitHander')
      .form-group
        input.form-control#name-ru(
          name='name-ru'
          placeholder='Название, например: Звездный ТЦ'
          required
          v-model='name_ru'
          autocomplete='off'
        )

      .input-group
        pattern-input#subdomain.form-control(
          placeholder='Поддомен, например: zvezdnyy-tc'
          :regExp='setting.regExp'
          v-model='subdomain'
        )
        span.input-group-addon#subdomain-addon .navimind.org

      .form-actions.center
        button.btn.btn-primary(type='submit') Продолжить
</template>

<script>
import { transliterate as tr, slugify } from 'transliteration'
import PatternInput from 'vue-pattern-input'

import ErrorMessages from '../components/ErrorMessages.vue'
import UrlMixin from '../mixins/Url'
import LoginScript from '../mixins/LoginScript'
import CreatedMixins from '../mixins/Created'
import Signed from '../components/Signed.vue'

export default {
  name: 'complex',

  components: { ErrorMessages, Signed, PatternInput },
  mixins: [ UrlMixin, LoginScript, CreatedMixins ],

  data () {
    return {
      name_ru: null,
      subdomain: '',
      error_messages: [],
      setting: {
        regExp: /[^a-z\-\d]/g
      }
    }
  },

  watch: {
    name_ru (n, o) {
      var temp = slugify(o).toLowerCase()
      if (!this.subdomain || this.subdomain == temp)
        this.subdomain = slugify(n.toLowerCase())
    }
  },

  methods: {
    submitHander () {
      this.error_messages = []
      if (!this.name_ru) this.error_messages.push('Название не может быть пустым.')
      if (!this.subdomain) this.error_messages.push('Поддомен не может быть пустым.')
      if (this.subdomain[0] === '-' || this.subdomain[this.subdomain.length - 1] === '-')
        this.error_messages.push('Поддомен не может начинаться или заканчиваться знаком "-"')
      if (this.error_messages.length > 0) return
      this.makeRequest()
    },

    makeRequest () {
      var params = {
        navigation_complex: {
          name_ru: this.name_ru,
          subdomain: this.subdomain
        }
      }
      var headers = {
        'X-Api-Token': this.$store.state.api_token
      }
      this.$http.post(`${this.url}/complex`, params, { headers: headers }).then(response => {
        var body = response.body
        if (body.error_code == 0) {
          this.$store.commit('addComplex', body.data)
          this.$router.push({ name: 'complex_status', params: { id: body.data.id } })
        } else {
          this.error_messages = response.body.error_message
        }
      }, response => {
        this.error_messages = response.body.error_message
      })
    }
  }
}
</script>

<style lang="css" scoped>
</style>
