import Vue from 'vue'
import App from '../apps/user_login/app.vue'
import VueResource from 'vue-resource'
import store from '../apps/user_login/support/store.js'
import router from '../apps/user_login/support/router.js'
import VueCookies from 'vue-cookies'

Vue.use(VueCookies)
Vue.use(VueResource)

Vue.config.productionTip = false

document.addEventListener('DOMContentLoaded', () => {
  const app = new Vue({
      router,
      store,
      ...App
    }).$mount('mount-point')
})
