var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "text-right", attrs: { id: "signed" } }, [
    _c("p", [
      _vm._v("Вы вошли как "),
      _c("b", [_vm._v(_vm._s(_vm.email) + " ")]),
    ]),
    _c("p", [
      _c(
        "a",
        {
          attrs: { href: "#" },
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.logout.apply(null, arguments)
            },
          },
        },
        [_vm._v("Выход")]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }