import Vue from 'vue'
import Router from 'vue-router'
import store from './store.js'

Vue.use(Router)

import Email from '../steps/Email'
import Confirmation from '../steps/Confirmation'
import Password from '../steps/Password'
import Complex from '../steps/Complex'
import ResendConfirmation from '../steps/ResendConfirmation'
import SignIn from '../steps/SignIn'
import ComplexesList from '../steps/ComplexesList'
import ResetPassword from '../steps/ResetPassword'
import ResetPasswordConfirmation from '../steps/ResetPasswordConfirmation'
import UnregisteredEmail from '../steps/UnregisteredEmail'
import ComplexStatus from '../steps/ComplexStatus'

const routes = [
  {
    path: '/',
    component: Email,
    name: 'email'
  },
  {
    path: '/confirmation',
    component: Confirmation,
    name: 'confirmation'
  },
  {
    path: '/password',
    component: Password,
    name: 'password'
  },
  {
    path: '/complex',
    component: Complex,
    name: 'complex'
  },
  {
    path: '/resend-confirmation',
    component: ResendConfirmation,
    name: 'resend-confirmation'
  },
  {
    path: '/sign-in',
    component: SignIn,
    name: 'sign-in'
  },
  {
    path: '/complexes-list',
    component: ComplexesList,
    name: 'complexes-list'
  },
  {
    path: '/reset-password',
    component: ResetPassword,
    name: 'reset-password'
  },
  {
    path: '/reset-password-confirmation',
    component: ResetPasswordConfirmation,
    name: 'reset-password-confirmation'
  },
  {
    path: '/unregistered',
    component: UnregisteredEmail,
    name: 'unregistered'
  },
  {
    path: '/complex-status/:id',
    component: ComplexStatus,
    name: 'complex_status'
  }
]
const router = new Router({
  routes
})

// router.beforeEach((to, from, next) => {
//   if (to.name !== 'email' && (!store.state.signed_in || !store.state.user)) {
//     console.log('here')
//     next({ name: 'email' })
//   }
//   else
//     next()
// })

export default router