var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.list.length
    ? _c("div", { staticClass: "alert alert-danger" }, [
        _c("b", [_vm._v("Пожалуйста исправьте указанные ошибки:")]),
        _c(
          "ul",
          _vm._l(_vm.list, function (error, index) {
            return _c("li", { key: index }, [_vm._v(_vm._s(error))])
          }),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }