<template lang="pug">
  #wrapper

    signed

    h3.title Выберите комплекс

    p Ваш аккаунт привязан к следующим комплексам. Нажмите на комплекс в списке, чтоб авторизоваться в панели управление комплекса.

    ul
      li(v-for='item in complexes' :key='item.id' @click.prevent='goto(item)')
        .data
          h4.name {{ item.name }}
          p.subdomain Адрес: {{ complexUrl(item.subdomain) }}
        .arrow(v-if="complexStatus(item)")
          i.fa.fa-chevron-right
        .gear(v-else)
          .w
            i.fa.fa-cog.fa-spin(style="font-size: 200%; color: #428bca;")
</template>

<script>
import Url from '../mixins/Url'
import LoginScript from '../mixins/LoginScript'
import Signed from '../components/Signed.vue'
import HandleResponse from '../mixins/HandleResponse'

export default {
  name: 'complexes-list',
  mixins: [ Url, LoginScript, HandleResponse ],

  components: { Signed },

  data () {
    return {
      timer: null
    }
  },

  computed: {
    complexes () {
      if (this.$store.state.complexes)
        return this.$store.state.complexes
      else {
        return []
      }
    }
  },

  created () {
    if (!this.$store.state.signed) this.$router.push({ name: 'email' })
  },

  mounted () {
    this.timer = setInterval(this.checkComplexes, 5000)
  },

  beforeDestroy () {
    clearInterval(this.timer)
  },

  methods: {
    complexStatus (item) {
      var statuses = ['idle', 'loaded']
      return item.is_building === false && statuses.includes(item.demo_data_status)
    },

    goto (complex) {
      // if (this.complexStatus(complex)) {
      //   this.loginByForm(complex.subdomain)
      // }
      this.loginByForm(complex.subdomain)
    },

    checkComplexes () {
      var headers = {
        'X-Api-Token': this.$store.state.api_token
      }
      this.$http.post(`${this.url}/check`, {}, { headers: headers }).then(response => {
        if (response.body.error_code == 0) { this.handleResponse(response.body.data) }
      }, response => {
      });
    }
  }
}
</script>

<style lang="css" scoped>
ul {
  margin: 20px 0 0 0;
  padding: 0;
  list-style-type: none;
}

li {
  display: flex;
  justify-content: space-between;
  border: 1px solid #dadada;
  border-bottom: 0;
  padding: 7px 10px;
  cursor: pointer;
  align-items: stretch;
}

li:hover {
  background-color: #eee;
}

li:last-child {
  border-bottom: 1px solid #dadada;
}

h4.name {
  font-weight: bold;
  margin: 3px 0;
}

p.subdomain {
  margin: 0;
}

.arrow {
  width: 10px;
  position: relative;
}

.gear {
  position: relative;
}

.gear .w {
  display: flex;
  align-items: center;
  height: 100%;
}

.arrow i {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
</style>
