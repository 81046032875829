<template lang="pug">
  #wrapper

    error-messages(:messages='error_messages')

    .text-center Адресс
      b {{ ` ${email} ` }}
      | не зарегистрирован

    .form-actions.center
      button.btn.btn-default(@click.prevent='goBack') Назад
</template>

<script>
import ErrorMessages from '../components/ErrorMessages.vue'
import UrlMixin from '../mixins/Url'

export default {
  name: 'unregistered-email',
  components: { ErrorMessages },
  mixins: [ UrlMixin ],

  data () {
    return {
      error_messages: []
    }
  },

  created () {
    if (!this.$store.state.unregistered_email) this.$router.push({ name: 'email' })
  },

  mounted () {
    document.getElementById('register').focus()
  },

  computed: {
    email () {
      return this.$store.state.unregistered_email
    }
  },

  methods: {
    goBack () {
      this.$store.commit('setUnregisteredEmail', null)
      this.$router.push({ name: "email" })
    },

    processUserState () {
      var user = this.$store.state.user
      if (user.new_user) {
        this.$router.push({ name: 'confirmation' })
      } else {
        if (user.confirmed)
          this.$router.push({ name: 'sign-in' })
        else
          this.$router.push({ name: 'resend-confirmation' })
      }
    }
  }
}
</script>

<style lang="css" scoped>
.btn {
  width: 166px;
}

.form-actions:last-child {
  margin-top: 0 !important;
}

.text-center {
  font-size: 110%;
}
</style>
