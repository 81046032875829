<template lang="pug">
  #wrapper

    error-messages(:messages='error_messages')

    form(@submit.prevent='submitHander')
      .form-group
        span.input-icon
          input.form-control#email(
            name='email'
            placeholder='Адрес электронной почты'
            required :value='email'
            disabled
          )
          i.fa.fa-envelope

      .form-group
        span.input-icon
          input.form-control#password(type='password' name='password' placeholder='Пароль' required v-model='password')
          i.fa.fa-unlock-alt

      .text-right
        a.forget(@click.prevent='forget') Забыли пароль?

      .form-actions.center
        button.btn.btn-primary(type='submit') Продолжить
      .form-actions.center
        button.btn.btn-default.btn-112(@click.prevent='$router.push({ name: "email" })') Назад
</template>

<script>
import ErrorMessages from '../components/ErrorMessages.vue'
import Url from '../mixins/Url'
import CreatedMixin from '../mixins/Created'
import HandleResponse from '../mixins/HandleResponse'

export default {
  name: 'sign-in',
  components: { ErrorMessages },
  mixins: [ Url, CreatedMixin, HandleResponse ],

  data () {
    return {
      password: null,
      error_messages: []
    }
  },

  mounted () {
    document.getElementById('password').focus()
  },

  computed: {
    email () {
      if (this.$store.state.user && this.$store.state.user.email)
        return this.$store.state.user.email
      else return ''
    }
  },

  methods: {
    submitHander () {
      this.error_messages = []

      if (!this.password) {
        this.error_messages.push('Пароль не может быть пустым.')
      }

      if (this.error_messages.length > 0) return

      var params = {
        user: {
          email: this.email,
          password: this.password
        }
      }

      this.$http.post(`${this.url}/auth`, params).then(response => {
        var body = response.body
        if (body.error_code == 0)
          this.handleResponse(body.data)
        else
          this.error_messages = response.body.error_message
      }, response => {
        this.error_messages = response.body.error_message
      });
    },

    forget () {
      this.$router.push({ name: 'reset-password' })
    }
  }
}
</script>

<style lang="css" scoped>
.forget {
  cursor: pointer;
}
</style>
