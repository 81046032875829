export default {
  computed: {
    url () {
      return `${this.$store.state.protocol}api.${this.$store.state.url_base}/login`
    },

    urlBase () {
      return this.$store.state.url_base
    },
  },

  methods: {
    complexUrl (subdomain) {
      return `${subdomain}.${this.urlBase}`
    },

    signInUrl (subdomain) {
      return `${this.$store.state.protocol}${this.complexUrl(subdomain)}/users/sign_in`
    }
  }
}