var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { attrs: { id: "wrapper" } },
    [
      _c("error-messages", { attrs: { messages: _vm.error_messages } }),
      _c("div", { staticClass: "text-center" }, [
        _vm._v("Подтвердите сброс пароля"),
      ]),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
              return _vm.submitHander.apply(null, arguments)
            },
          },
        },
        [
          _c("div", { staticClass: "form-group" }, [
            _c("span", { staticClass: "input-icon" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                staticClass: "form-control",
                attrs: {
                  id: "email",
                  name: "email",
                  placeholder: "Адрес электронной почты",
                  required: "",
                },
                domProps: { value: _vm.email },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  },
                },
              }),
              _c("i", { staticClass: "fa fa-envelope" }),
            ]),
          ]),
          _vm._m(0),
          _c("div", { staticClass: "form-actions center" }, [
            _c(
              "button",
              {
                staticClass: "btn btn-default btn-112",
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.$router.push({ name: "sign-in" })
                  },
                },
              },
              [_vm._v("Назад")]
            ),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "form-actions center" }, [
      _c(
        "button",
        { staticClass: "btn btn-primary", attrs: { type: "submit" } },
        [_vm._v("Продолжить")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }