<template lang="pug">
  .box-register
    div#logo-wrapper(align="right")
      img#logo(src='../../../assets/images/logo.svg')

    router-view
</template>

<script>
export default {
  name: 'user-registration',

  created () {
    var el = document.getElementById('raw-data')
    if (el.dataset.url) this.$store.commit('setUrlBase', el.dataset.url)
    if (el.dataset.protocol) this.$store.commit('setProtocol', el.dataset.protocol)
  },

  methods: {
  }
}
</script>

<style lang="css" scoped>
.box-register {
  display: block !important;
  padding-bottom: 30px !important;
}

#logo-wrapper {
  margin-bottom: 40px;
}
</style>

<style>
.form-actions:last-child {
  margin-top: 0 !important;
}

.btn-112 {
  width: 112px;
}
</style>
