var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "box-register" },
    [_vm._m(0), _c("router-view")],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "logo-wrapper", align: "right" } }, [
      _c("img", {
        attrs: { id: "logo", src: require("../../../assets/images/logo.svg") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }